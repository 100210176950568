* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

#root{
  height: 100%;
}

body {
  height: 100%;
  background: #f4f5fa;

  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
